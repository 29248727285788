<template>
  <div>
    <div class="row m-0 justify-content-center" >
      <div class="col-md-11 px-0 my-3 card_item_inner">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-12 p-0 border_top" v-for="(donationItem, key) in details.donations" :key="key">
                <div class="row m-0 align-items-center borders py-2">
                  <div class="col-12 px-4">
                    <div class="row mx-0 mt-3">
                      <div class="col-auto p-0">
                        <Select
                          v-model="donationItem.project"
                          id="project"
                          :options="projects"
                          placeholder="Projects"
                          displayValue="name"
                          color="gold"
                          size="small"
                        />
                      </div>
                    </div>
                    <div class="row mx-0 my-3 align-items-center">
                      <div class="col-9 p-0">
                        <div class="row">
                          <div class="col-12 col-md-auto mb-1">
                            <CurrencyField :label="donationItem.donationType.description" v-model="donationItem.amount" size="small" color="outline-green" />
                          </div>
                          <!-- <div class="col-6" v-for="(donation, key) in donationItem.project.projectDonationTypeMaps" :key="key">
                            <div class="row m-0">
                              <div class="col-12 p-0">
                                d1
                              </div>
                            </div>
                            <div class="row m-0">
                              <div class="col-12 bold">
                                {{donation.donationType.description}}
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                      <div class="col-3 bold text-end d-none d-md-block">
                        {{ donationItem.currency.symbol }} {{ parseFloat(Math.round(donationItem.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
                      </div>
                    </div>
                    <div class="row my-md-3 mt-3 mb-2">
                      <!-- <div class="col-12 col-md-auto mb-1">
                        <Button color="gold" icon="arrow" size="small" btnText="Add Donation Type">
                          <IconPlus size="" />
                        </Button>
                      </div> -->
                      <div class="col-12 bold text-end d-block d-md-none mt-2">
                        {{ donationItem.currency.symbol }} {{ parseFloat(Math.round(donationItem.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0 justify-content-center" >
      <div class="col-md-11 px-0 my-3">
        <div class="row align-items-center">
          <div class="col-md-4 text-center green-text mb-3 mb-md-0">
            <Button color="green" width="100" :btnText="displayTotal" />
            <div class="medium green-text font10 letter_spacing mt-2">
              DONATION TOTAL
            </div>
          </div>
          <div class="col-md-4 text-center mb-3 mb-md-0">
            <div class="row m-0 selectFrequency">
              <div class="col-12 form-control holder">
                <select
                  v-model="details.frequency"
                  class="form-control form-control-inner w-100 medium "
                >
                  <option :value="option.val" v-for="option in frequencyOptions" :key="option" class="normal-text-dark" >{{option.display}}</option>
                </select>
                <div class="dropdown_icon"><ios-arrow-down-icon /></div>
              </div>
            </div>
            <div class="medium green-text font10 letter_spacing mt-2">
              FREQUENCY
            </div>
          </div>
          <div class="col-md-4 text-center green-text mb-3 mb-md-0">
            <TextField type="date" color="gold" placeholder="Start Date" v-model="jsDate" />
            <div class="medium green-text font10 letter_spacing mt-2">
              PAYMENT DATE
            </div>
          </div>
        </div>
        <!-- <div class="row mt-2">
          <div class="col-4 text-center">
            <div class="medium green-text font10 letter_spacing">
              DONATION TOTAL
            </div>
          </div>
          <div class="col-4 text-center">
            <div class="medium green-text font10 letter_spacing">
              FREQUENCY
            </div>
          </div>
          <div class="col-4 text-center">
            <div class="medium green-text font10 letter_spacing">
              PAYMENT DATE
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('../components/CurrencyField.vue')),
    TextField: defineAsyncComponent(() => import('../components/TextField.vue')),
    Select: defineAsyncComponent(() => import('../components/SelectProject.vue')),
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue'))
  },
  props: ['item', 'itemDetails'],
  name: 'Recurring Donation Edit Popup',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      donorType: '',
      selectedProject: {},
      selectedParentProject: {},
      selectedChildProject: {},
      donation: {
        amount: '',
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      },
      donationType: '',
      frequencyOptions: [
        {
          val: 'DAILY',
          display: 'Daily'
        },
        {
          val: 'WEEKLY',
          display: 'Weekly'
        },
        {
          val: 'MONTHLY',
          display: 'Monthly'
        }
      ],
      jsDate: null,
      details: null
    }
  },
  beforeMount () {
    this.donation.currency = this.defaultCurrency
    this.details = { ...this.itemDetails }
  },
  mounted () {
    this.setDateFromMillis(this.itemDetails.startDate)
    this.setFrequency(this.itemDetails.frequency)
  },
  watch: {
    details: {
      deep: true,
      handler (val) {
      }
    },
    selectedProject () {
      this.donation.projectId = this.selectedProject.adminProjectId
    },
    selectedParentProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedChildProject = {}
        this.selectedProject = this.selectedParentProject
      } else {
        this.selectedProject = this.selectedParentProject
      }
    },
    selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedProject = this.selectedChildProject
      }
    },
    jsDate () {
      this.setDate(this.jsDate)
    }
  },
  computed: {
    ...mapGetters([
      'projects',
      'user',
      'organisations'
    ]),
    displayTotal () {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(this.details.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    frequency () {
      const lowercase = this.details.frequency.toLowerCase()
      return lowercase[0].toUpperCase() + lowercase.slice(1)
    },
    childProjects () {
      return this.projects.filter(project => {
        if (this.selectedParentProject && project.parentProjectID !== this.selectedParentProject.projectID) {
          return false
        }
        return true
      })
    },
    donorTypes () {
      const types = [
        this.user
      ]
      // if (this.organisations.length > 0) {
      //   this.organisations.forEach(organisation => {
      //     types.push(organisation)
      //   })
      // }
      return types
    }
  },
  methods: {
    ...mapActions([
      'addToCart',
      'clearCart'
    ]),
    goDonate () {
      this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    addDonationToCart () {
      this.selectedProject.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType !== this.donationType) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: this.donationType, amount: this.donation.amount })
        }
      })
      this.addToCart(this.donation)
      this.isAdded = true
      this.donation = {
        amount: '',
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      }
      this.selectedProject = {}
      this.selectedParentProject = {}
      this.selectedChildProject = {}
      setTimeout(() => {
        this.isAdded = false
      }, 2000)
    },
    setDateFromMillis (val) {
      const date = DateTime.fromMillis(parseInt(val))
      this.jsDate = date.toFormat('yyyy-MM-dd')
      this.details.startDate = date.ts
    },
    setDate (val) {
      const date = DateTime.fromFormat(val, 'yyyy-MM-dd')
      this.details.startDate = date.ts
    },
    setFrequency (val) {
      this.details.frequency = val
    }
  }
}
</script>
